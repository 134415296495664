import { action } from '@ember/object';
import {
  BACKGROUND_PROCESS_STATUS,
  BACKGROUND_PROCESS_TYPES,
  SHOULD_POLL_BACKGROUND_PROCESS,
  URL_BOOKGROUP_PURCHASE_RECOMMENDATION_DOWNLOAD,
  URL_BOOKGROUP_PURCHASE_RECOMMENDATION_UPLOAD,
} from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import config from '@mvb/tix-ui/config/environment';
import Controller from '@ember/controller';

export default class PartyTitleUploadController extends Controller {
  @service api;
  @service errors;
  @service file;
  @service intl;
  @service notifications;
  @service store;
  @service user;

  @tracked validationErrors;
  @tracked backgroundProcess;
  @tracked uploadedFile;

  uploadUrl = `${config.APP.api}${URL_BOOKGROUP_PURCHASE_RECOMMENDATION_UPLOAD}`;

  constructor() {
    super(...arguments);
    this.loadExistingUploadTask.perform();
  }

  get uploadedFileName() {
    return this.user.selectedParty?.party?.prFileName;
  }

  @task
  @waitFor
  *loadExistingUploadTask() {
    try {
      let response = yield this.store.query('background-process', {
        filter: { type: BACKGROUND_PROCESS_TYPES.BOOKGROUP_PURCHASE_RECOMMENDATION_UPLOAD },
      });
      let loadedBackgroundProcess = response[0];
      if (
        loadedBackgroundProcess &&
        (this.backgroundProcessIsRunning(loadedBackgroundProcess) ||
          this.backgroundProcessIsCompleted(loadedBackgroundProcess))
      ) {
        this.backgroundProcess = loadedBackgroundProcess;
      }
      // this will be only executed in tests!
      if (!SHOULD_POLL_BACKGROUND_PROCESS) {
        this.onTitleUploadCompleted();
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *downloadTask() {
    try {
      let { blob: file } = yield this.api.get(
        `${URL_BOOKGROUP_PURCHASE_RECOMMENDATION_DOWNLOAD}${this.user.selectedParty?.party?.mvbId}`
      );
      yield this.file.saveAs(file, this.uploadedFileName);
    } catch {
      let message = this.intl.t('partyTitleUpload.notification.downloadError');
      this.notifications.error(message, { autoClear: false });
    }

    return true;
  }

  @action
  download(event) {
    event.preventDefault();
    return this.downloadTask.perform();
  }

  @action
  onFileUploadError(response) {
    let responseMessage = response?.errors[0]?.meta?.messageText;

    let message =
      responseMessage === 'tooManyFlags'
        ? this.intl.t('partyTitleUpload.notification.tooManyFlags')
        : this.intl.t('partyTitleUpload.notification.uploadError');

    this.validationErrors = undefined;
    this.notifications.error(message, { autoClear: false });
  }

  @action
  async onFileUploadSuccess(file, response) {
    this.uploadedFile = file;
    this.backgroundProcess = null;
    this.validationErrors = undefined;
    await this.initBackgroundProcess(response);
  }

  @action
  onTitleUploadCompleted() {
    if (this.backgroundProcess.result) {
      let encodedResult = atob(this.backgroundProcess.result);
      this.processResult(this.uploadedFile, JSON.parse(encodedResult));
    }
  }

  @action
  onTitleUploadFailed() {
    if (this.uploadedFile) {
      this.notifications.error(this.intl.t('partyTitleUpload.notification.uploadError'), { autoClear: false });
    }
  }

  async initBackgroundProcess(responseBody) {
    let processId = responseBody.id;

    delete responseBody.id;

    let attributes = {
      ...responseBody,
    };

    this.store.pushRecordAttributesWithRelationships('background-process', processId, attributes, {});

    this.backgroundProcess = await this.store.findRecord('background-process', processId);
  }

  processResult(file, response) {
    if (response?.length > 0) {
      let message = this.intl.t('partyTitleUpload.notification.uploadSuccessWithWarnings');

      this.encodeResponseCauses(response);

      this.validationErrors = response;

      if (this.uploadedFile) {
        this.notifications.warning(message, { autoClear: false });
      }
    } else {
      let message = this.intl.t('partyTitleUpload.notification.uploadSuccess');

      this.validationErrors = undefined;
      if (this.uploadedFile) {
        this.notifications.success(message, { autoClear: false });
      }
    }

    if (this.user.selectedParty?.party?.id && this.uploadedFile) {
      this.store.pushRecordAttributes('party', this.user.selectedParty.party.id, { prFileName: file.name });
    }
  }

  encodeResponseCauses(response) {
    for (let responseElement of response) {
      let encodedCause = new Uint8Array(Array.from(responseElement.cause).map((char) => char.codePointAt(0)));
      responseElement.cause = new TextDecoder('utf8').decode(encodedCause);
    }
  }

  backgroundProcessIsRunning(backgroundProcess) {
    return backgroundProcess.currentStep && backgroundProcess.status === BACKGROUND_PROCESS_STATUS.RUNNING;
  }

  backgroundProcessIsCompleted(backgroundProcess) {
    return backgroundProcess.currentStep && backgroundProcess.result;
  }

  reset() {
    this.validationErrors = undefined;
  }
}
