import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import { URL_PREVIEW_HISTORY } from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import createOptions from '@mvb/tix-ui/utils/create-options';
import ModalSendPreviews from '@mvb/tix-ui/components/preview/send-previews-modal';
import PreviewClonePreviewModalIndexComponent from '@mvb/tix-ui/components/preview/clone-preview-modal';
import saveBlob from '@mvb/tix-ui/utils/save-blob';
import Service, { service } from '@ember/service';

const MAX_ALLOWED_PREVIEWS_FOR_SENDING = 100;

/**
 * This service holds all the functionality you can find in the function bar for previews (e.g. previews overview page or preview-list detail page).
 * At least the functionality that would otherwise be duplicated.
 */
export default class PreviewsService extends Service {
  @service api;
  @service errors;
  @service intl;
  @service manageList;
  @service modals;
  @service notifications;
  @service progress;
  @service router;

  @action
  async downloadHistory(previewId) {
    this.notifications.success(this.intl.t('previewsService.notification.history'));
    await this.downloadHistoryTask.perform(previewId);
  }

  @action
  async onClonePreview(preview) {
    // if the preview is locked don't run into the cloning logic
    if (preview.locked || preview.isLocked) {
      return false;
    }

    try {
      let progress = await this.modals.open(PreviewClonePreviewModalIndexComponent, {
        previewId: preview.id,
        previewType: preview.type,
        cmcType: preview.cmcType,
      });

      if (progress) {
        this.notifications.success(this.intl.t('layoutIconbarPreviewDetail.notifications.cloneSuccess'));
        return true;
      }
    } catch (error) {
      this.errors.handle(error);
      return false;
    }
  }

  async onSendPreview(total, filter) {
    let selectedElementsCount = this.manageList.determineSelectedElementsCount(total);

    if (selectedElementsCount === 0) {
      this.modals.notify({
        message: this.intl.t('previewsIndex.notification.noSelection'),
        close: this.intl.t('modalNotify.action.confirm'),
      });

      return false;
    }

    if (selectedElementsCount > MAX_ALLOWED_PREVIEWS_FOR_SENDING) {
      this.modals.notify({
        message: this.intl.t('previewsIndex.notification.tooManySelected', {
          selected: this.intl.formatNumber(selectedElementsCount),
        }),
        close: this.intl.t('modalNotify.action.confirm'),
      });

      return false;
    }

    let result = await this.modals.open(ModalSendPreviews, { filter });

    if (!result) {
      return false;
    }

    let progress = this.progress.add({
      message: this.intl.t('previewSendPreviewsModal.text.progress'),
    });

    try {
      await this.sendPreviewsTask.perform({ ...result });

      progress.remove();
      this.notifications.success(this.intl.t('previewSendPreviewsModal.notification.success'));
    } catch (error) {
      progress.remove();
      if (error.messages?.[0]?.code === 'error.recipients.tooMany') {
        this.notifications.error(this.intl.t('previewSendPreviewsModal.validation.tooManyRecipients'), {
          autoClear: true,
        });
      } else {
        this.errors.handle(error);
      }
    }

    return true;
  }

  @task
  @waitFor
  *downloadHistoryTask(previewId) {
    try {
      let url = URL_PREVIEW_HISTORY.replace('{ID}', previewId);
      let { attachmentName, blob } = yield this.api.get(url);

      saveBlob(blob, attachmentName);
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *loadPreviewTypesTask() {
    if (isPresent(this.loadPreviewTypesTask.lastSuccessful?.value)) {
      return this.loadPreviewTypesTask.lastSuccessful.value;
    }

    try {
      let response = yield this.api.get('/preview/types');
      return createOptions(this.intl, response, 'cl_511');
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *sendPreviewsTask(data) {
    yield this.api.postJSON('/previews/send', {
      ...data,
    });
  }
}
