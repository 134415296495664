import { attr, belongsTo } from '@ember-data/model';
import { FALLBACK_COVER_URL, PREVIEW_STATUS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Model from './-base';

export default class SearchPreviewModel extends Model {
  @service intl;

  // attributes
  // @attr() agent;
  @attr() changes;
  @attr('string') cmcType;
  @attr('string', FALLBACK_COVER_URL) coverUrl;
  @attr('date') creationDate;
  @attr() creator;
  @attr() description;
  @attr() descriptionListView;
  @attr('date') modificationDate;
  @attr() name;
  @attr('boolean') onList;
  @attr('boolean') sentOnBehalfOfSelectedParty;
  @attr('date') publicationDate;
  @attr() sender;
  @attr() senderName;
  @attr() status;
  @attr() type;
  @attr() isLocked;

  // attributes as list
  @attr() batchIds;
  @attr() batchNames;
  @attr() party;

  @belongsTo('preview-edit-status', { async: true, inverse: 'searchPreview' }) previewEditStatus;

  get abilityModel() {
    return {
      creator: this.creator,
      sender: this.sender,
      sentOnBehalfOfSelectedParty: this.sentOnBehalfOfSelectedParty,
      type: this.type,
    };
  }

  get cmcTypeTranslation() {
    return this.cmcType ? ` ${this.intl.t(`modelPreview.text.cmcType.${this.cmcType}`)}` : '';
  }

  get isDraft() {
    return this.status === PREVIEW_STATUS.DRAFT;
  }

  get typeTranslation() {
    return this.type ? this.intl.t(`cl_511_${this.type}`) + this.cmcTypeTranslation : '';
  }
}
