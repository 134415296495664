import { PARTY_PERMISSIONS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Ability from './-base';

export default class ProductAbility extends Ability {
  @service features;
  @service session;
  @service user;

  get createPromotionalPackage() {
    return (
      this.features.isEnabled('createUpdatePromotionalPackage') &&
      this.user.selectedParty?.isPremium &&
      this.user.hasPermission(PARTY_PERMISSIONS.CREATE_ACTIONS_SPECIALS)
    );
  }

  get createSpecial() {
    return (
      this.features.isEnabled('createUpdateSpecial') &&
      this.user.selectedParty?.isPremium &&
      this.user.hasPermission(PARTY_PERMISSIONS.CREATE_ACTIONS_SPECIALS)
    );
  }

  get delete() {
    return !this.model?.isNew && this.model?.isProductCollection && this.edit;
  }

  get edit() {
    if (this.model?.isPromotionalPackage && !this.features.isEnabled('createUpdatePromotionalPackage')) {
      return false;
    }

    if (this.model?.isThemenspecial && !this.features.isEnabled('createUpdateSpecial')) {
      return false;
    }

    let assignedPartyForPublisherByMainPublisher = this.user.assignedParties.find(
      (assignedParty) => assignedParty.isPremium && assignedParty.party?.mvbId === this.model?.mainPublisher?.idValue
    );

    let assignedPartyForPublisherByPublisherMvbId = this.user.assignedParties.find(
      (assignedParty) => assignedParty.isPremium && assignedParty.party?.mvbId === this.model?.publisherMvbId
    );

    let necessaryPermission = this.model?.isProductCollection
      ? PARTY_PERMISSIONS.CREATE_ACTIONS_SPECIALS
      : PARTY_PERMISSIONS.EDIT_TITLE_DETAILS;

    return (
      this.user.selectedParty?.isPremium &&
      (assignedPartyForPublisherByMainPublisher?.hasPermission(necessaryPermission) ||
        assignedPartyForPublisherByPublisherMvbId?.hasPermission(necessaryPermission))
    );
  }

  get note() {
    return this.user.current?.hasAssignedParties ?? false;
  }

  get orderAdvertisingMaterial() {
    return !this.user.hasPermission(PARTY_PERMISSIONS.REJECT_ADVERTISING_MATERIAL_ORDER);
  }

  get print() {
    return false;
  }

  get viewAdvertising() {
    return this.user.current?.hasAssignedParties;
  }

  get viewAnnouncementDate() {
    return this.user.assignedParties?.some((assignedParty) => assignedParty.party?.isPublisher);
  }

  get viewContainedInPreviews() {
    return this.session.isAuthenticated;
  }

  get viewContainedInProductCollections() {
    return this.session.isAuthenticated;
  }

  get viewContainedInSpecialsOnly() {
    return (
      this.session.isAuthenticated &&
      (this.user.current?.isBlogger || this.user.current?.isReader || this.user.current?.isJournalist)
    );
  }

  get viewMarketing() {
    return this.user.current?.hasAssignedParties;
  }

  get viewNetgalleyReadCopy() {
    let userIsCorporate = this.user.current?.hasAssignedParties;
    let publisherOfProductIsPremium = this.model?.mainPublisher?.premium;
    let isNoProductCollection = !this.model?.isProductCollection;
    return (
      this.features.isEnabled('netgalleyReadCopy') &&
      userIsCorporate &&
      publisherOfProductIsPremium &&
      isNoProductCollection
    );
  }

  get viewProductCollectionData() {
    let mvbIds = this.user.assignedPartiesPermissionsMap?.map((party) => party.mvbId) || [];
    return new Set(mvbIds).has(this.model.publisherMvbId);
  }

  get viewPublishedPage() {
    let assignedPartyForPublisher = this.user.assignedParties.find(
      (assignedParty) => assignedParty.isPremium && assignedParty.party?.mvbId === this.model?.mainPublisher?.idValue
    );
    let necessaryPermission = this.model?.isProductCollection
      ? PARTY_PERMISSIONS.CREATE_ACTIONS_SPECIALS
      : PARTY_PERMISSIONS.EDIT_TITLE_DETAILS;

    return (
      this.features.isEnabled('publishedPageForProducts') &&
      !this.model.isInDraftState && // a product in draft does not have any published sections to show
      this.user.selectedParty?.isPremium &&
      assignedPartyForPublisher?.hasPermission(necessaryPermission)
    );
  }

  get viewSalesFiguresHugendubel() {
    return this.user.hasPermission(PARTY_PERMISSIONS.SHOW_COMPARATIVE_PRODUCTS_SNIPPET);
  }

  get viewSalesNotes() {
    return this.user.current?.hasAssignedParties;
  }

  get viewWawiBox() {
    let userIsPremiumBookstore =
      (this.user.selectedParty?.isPremium && this.user.selectedParty?.party?.isBookstore) || false;
    let userHasEuroSalesFlowERPActivated = this.user.isErpSalesEnabled();
    let productIsNoSpecial = !this.model?.isThemenspecial;

    return (
      this.features.isEnabled('eurosoftWawiSettings') &&
      userIsPremiumBookstore &&
      userHasEuroSalesFlowERPActivated &&
      productIsNoSpecial
    );
  }
}
