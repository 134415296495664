import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-additional-information-filter={{@filter.name}}>\n  <Ui::Input::Select\n    @disabled={{@disabled}}\n    @displayCheckboxes={{@displayCheckboxes}}\n    @hideSelected={{true}}\n    @label={{@filter.text}}\n    @multiple={{true}}\n    @name={{@filter.name}}\n    @onChange={{this.onChange}}\n    @options={{@filter.filterItems}}\n    @placeholder={{t \"searchFilterAdditionalInformationMultiple.placeholder\"}}\n    @value={{this.value}}\n    {{did-update this.updateValueFromCurrentQueryParams this.selectedValueFromQueryParams}}\n  />\n</div>", {"contents":"<div data-test-additional-information-filter={{@filter.name}}>\n  <Ui::Input::Select\n    @disabled={{@disabled}}\n    @displayCheckboxes={{@displayCheckboxes}}\n    @hideSelected={{true}}\n    @label={{@filter.text}}\n    @multiple={{true}}\n    @name={{@filter.name}}\n    @onChange={{this.onChange}}\n    @options={{@filter.filterItems}}\n    @placeholder={{t \"searchFilterAdditionalInformationMultiple.placeholder\"}}\n    @value={{this.value}}\n    {{did-update this.updateValueFromCurrentQueryParams this.selectedValueFromQueryParams}}\n  />\n</div>","moduleName":"@mvb/tix-ui/components/search/filter/additional-information-multiple/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/additional-information-multiple/index.hbs"}});
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterAdditionalInformationMultipleIndexComponent extends SearchFilterBaseComponent {
  @service queryParams;

  constructor() {
    super(...arguments);
    next(this, () => {
      // initialize the value with the data from the queryParams
      this.updateValueFromCurrentQueryParams();
    });
  }

  get selectedValueFromQueryParams() {
    return this.queryParams.currentQueryParams[this.args.filter.name];
  }

  @action
  onChange(value) {
    this.value = value;
    this.search({
      [this.args.filter.name]: Array.isArray(this.value) ? this.value.join(',') : this.value,
    });
  }

  @action
  updateValueFromCurrentQueryParams() {
    this.value = this.selectedValueFromQueryParams;
  }
}
